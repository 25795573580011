<template>
  <div
    class="conventionWrapper"
  >
    <div
      v-if="data.companySelected === 'cm'"
      id="conventionHeader"
      class="d-flex"
    >
      <div class="conventionHeaderLogo">
        <v-img
          width="300"
          :src="require('@/assets/images/logos/dark_logo_transparent_background.png')"
        >
          <v-btn
            icon
            class="deleteIcon align-right"
            @click="removeLogo"
          >
            <v-icon color="error">
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </v-btn>
        </v-img>
      </div>
    </div>
    <v-card
      elevation="0"
      outlined
    >
      <v-card-title
        class="d-flex justify-center"
        primary-title
      >
        <h3>
          CONVENTION DE FORMATION PROFESSIONNELLE
        </h3>
      </v-card-title>
      <v-card-title
        class="d-flex justify-center"
      >
        <h3>
          <h5>(Article L.6353-1 du Code du travail)</h5>
        </h3>
      </v-card-title>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          1 - L'organisme de formation
        </h4>
      </v-card-title>
      <v-card-text v-if="data.companySelected === 'cm'">
        <ul>
          <li>
            <b>Nom de l'organisme :</b> La Formation Pro - Call Masther
          </li>
          <li>
            <b>Adresse : </b>100 - 102 Boulevard Sadi Carnot
          </li>
          <li>
            <b>Code Postal :</b> 06110
          </li>
          <li>
            <b>Ville :</b>Le Cannet
          </li>
          <li>
            <b>Numéro SIRET :</b>89071917200029
          </li>
          <li>
            <b>Tel :</b> 09.73.89.63.91
          </li>
          <li>
            <b>E-mail :</b>contact@laformationpro.fr
          </li>
        </ul>
        <p v-if="data.companySelected === 'cm'">
          Organisme de enregistré sous le numéro de déclaration d'activité 93060995706 auprès de la Direction Régionale des Entreprises, de la Concurrence, de la Consommation, du Travail et de l’Emploi (Préfet de Région Provence-Alpes-Côte d’Azur). Cet enregistrement ne vaut pas agrément de l’État.
        </p>
      </v-card-text>
      <v-card-text v-else>
        <ul>
          <li>
            <b>Nom de l'organisme :</b> MPASSIONATE Formation
          </li>
          <li>
            <b>Adresse : </b> 6 Rue des Bateliers
          </li>
          <li>
            <b>Code Postal :</b> 92110
          </li>
          <li>
            <b>Ville :</b> Clichy
          </li>
          <li>
            <b>Numéro SIRET :</b> 91944767200016
          </li>
          <li>
            <b>Tel :</b> 09.73.89.63.91
          </li>
          <li>
            <b>E-mail :</b> mpassionate@outlook.fr
          </li>
        </ul>
        <p>
          Organisme de enregistré sous le numéro de déclaration d'activité 11922552092 auprès de la Direction Régionale des Entreprises, de la Concurrence, de la Consommation, du Travail et de l’Emploi (Préfet de Région Ile de France). Cet enregistrement ne vaut pas agrément de l’État.
        </p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          2 - L'entreprise
        </h4>
      </v-card-title>
      <v-card-text>
        <ul>
          <li>
            <b>
              Raison sociale : {{ data.bc.name }}
            </b>
          </li>
          <li>
            <b>
              Adresse : {{ data.bc.addressLine1 }}
            </b>
          </li>
          <li>
            <b>
              Code Postal : {{ data.bc.postcode }}
            </b>
          </li>
          <li>
            <b>
              Ville : {{ data.bc.city }}
            </b>
          </li>
          <li>
            <b>
              Numéro SIRET : {{ data.bc.siret }}
            </b>
          </li>
          <li>
            <b>
              Tel : {{ data.bc.phone }}
            </b>
          </li>
          <li>
            <b>
              E-mail : {{ data.bc.email }}
            </b>
          </li>
          <li>
            <b>
              Représentée par : {{ data.bc.person }}
            </b>
          </li>
        </ul>
        <p>
          Est conclue la convention suivante, en application des dispositions du Livre III de la Sixième partie du Code du travail portant organisation de la formation professionnelle continue.
        </p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          Article 1 : Objet de la convention
        </h4>
      </v-card-title>
      <v-card-text>
        <p>
          L'organisme {{ data.companySelected ==='cm' ? 'Call Masther - La Formation Pro' : 'MPASSIONATE Formation ' }} organisera l'action de formation suivante :
        </p>
        <ul>
          <li>
            <b>
              Intitulé du stage : {{ data.course.name }}
            </b>
          </li>
          <li>
            <b>
              Titre et qualité des formateurs :  {{ data.course.teacher }}
            </b>
          </li>
          <li>
            <b>
              Objectifs :
            </b>
            Joint sur programme de formation Programme et méthodes : joints en annexe 1.
          </li>
          <li>
            <b>
              Type d’action de formation (article L.6313-1 du Code du travail) :
            </b>
            Plan de développement de compétences
          </li>
        </ul>
        <v-row class="mt-3">
          <v-col>
            <b>
              Dates :  {{ data.course.dates }}
            </b>
          </v-col>
          <v-col>
            <b>
              Durée :  {{ data.course.duration }}
            </b>
          </v-col>
          <v-col>
            <b>
              Lieu :  {{ data.course.location }}
            </b>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>Article 2 : Effectif(s) à former</h4>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nom
                </th>
                <th class="text-left">
                  Prénom
                </th>
                <th class="text-left">
                  Fonction
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="student in data.students"
                :key="student.lastname"
              >
                <td>{{ student.lastname }}</td>
                <td>{{ student.firstname }}</td>
                <td>{{ student.role }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>Article 3 : Disposition financières</h4>
      </v-card-title>
      <v-card-text>
        <p>En contrepartie de cette action de formation, l’employeur s’acquittera des coûts suivants :</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                </th>
                <th class="text-left">
                  Coût unitaire (€ / heure)
                </th>
                <th class="text-left">
                  Nombre d'heures
                </th>
                <th class="text-left">
                  Nombre de stagiaire(s)
                </th>
                <th class="text-left">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(course, index ) in data.courseTable"
                :key="index"
              >
                <td>{{ course.name }}</td>
                <td> {{ course.hourlyCost }}</td>
                <td> {{ course.nbHours }}</td>
                <td> {{ course.nbStudents }}</td>
                <td> {{ course.total }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p>Je souhaite la prise en charge du salaire (Formation réalisée sur temps de travail)</p>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Soit un total de :  </td>
                <td>{{ data.course.total }} €</td>
              </tr>
              <tr>
                <td>Sommes versées par l'entreprise à titre d'acomptes :</td>
                <td>{{ data.course.acompte }} €</td>
              </tr>
              <tr>
                <td>Sommes restant dues : </td>
                <td>{{ data.course.topay }} €</td>
              </tr>
              <tr>
                <td>Total général : </td>
                <td> {{ data.course.total }} € NET DE TAXES</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p>Organisme de formation non assujetti à la TVA conformément à l’article 261-4-4 dit Code Général des Impôts</p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          Article 4 : Modalités de financement
        </h4>
      </v-card-title>
      <v-card-text>
        <p>Le paiement sera dû à la fin de la formation</p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          Article 5 : Débit ou abandon
        </h4>
      </v-card-title>
      <v-card-text>
        <p>En cas de dédit par l’entreprise à moins de 3 Jours francs avant le début de l’action mentionnée à l’article 1, ou d’abandon en cours de formation par un ou plusieurs stagiaires, l’organisme remboursera sur le coût total, les sommes qu’il n’aura pas réellement dépensées ou engagées pour la réalisation de ladite action.</p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          Article 6 : Différends éventuels
        </h4>
      </v-card-title>
      <v-card-text>
        <p>Si une contestation ou un différend ne peuvent être réglés à l’amiable, le Tribunal de {{ data.companySelected ==='cm' ? 'Cannes' : 'Clichy' }} sera seul compétent pour régler le litige.</p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          Article 7 : Moyens de sactions de l'assiduité
        </h4>
      </v-card-title>
      <v-card-text>
        <p>Les horaires de stage sont fixés par la Direction ou le responsable de l’organisme de formation et portés à la connaissance des stagiaires soit par voie d’affichage, soit à l’occasion de la remise aux stagiaires du programme de stage. Les stagiaires sont tenus de respecter ces horaires de stage sous peine de l’application des dispositions suivantes</p>
        <ul>
          <li>En cas d’absence ou de retard au stage, les stagiaires doivent avertir le formateur ou le secrétariat de l’organisme qui a en charge la formation et s’en justifier. Par ailleurs, les stagiaires ne peuvent s’absenter pendant les heures de stage, saufcirconstances exceptionnelles précisées par la Direction ou le responsable de l’organisme de formation de l’organisme.</li>
          <li>
            Lorsque les stagiaires sont des salariés en formation dans le cadre du plan de formation, l’organisme doit informer préalablement l’entreprise de ces absences. Toute absence ou retard non justifié par des circonstances particulières constitue une faute passible de sanctions disciplinaires.
          </li>
          <li>
            En outre, pour les stagiaires demandeurs d’emploi rémunérés par l’État ou une région, les absences non justifiées entraîneront, en application de l’article R 634145 du Code du Travail, une retenue de rémunération proportionnelle à la durée des dites absences.
          </li>
          <li>
            Par ailleurs, les stagiaires sont tenus de remplir ou signer obligatoirement et régulièrement, au fur et à mesure du déroulement de l’action, l’attestation d’assiduité, et en fin de stage le bilan de formation ainsi que l’attestation de suivi de stage.
          </li>
          <li>En outre, un relevé de connexions sera délivré aux stagiaires en formation distanciel/elearning</li>
          <li>L’apprenant devra prévenir {{ data.companySelected === 'cm' ? 'Call Masther' : 'MPASSIONATE Formation' }} au moins 48 heures à l’avance s’il ne peut pas assister à son cours en visioconférence. Passé ce délais, l’heure de visioconférence sera décomptée du forfait de formation de l’apprenant.</li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          Article 8 : Sanction
        </h4>
      </v-card-title>
      <v-card-text>
        <p>Tout manquement du stagiaire à l’une des prescriptions du présent règlement intérieur pourra faire l’objet d’une sanction. Constitue une sanction au sens de l’article R 6352-3 du Code du Travail toute mesure, autre que les observations verbales, prises par le responsable de l’organisme de formation de l’organisme de formation ou son représentant, à la suite d’un agissement du stagiaire considéré par lui comme fautif, que cette mesure soit de nature à affecter immédiatement ou non la présence de l’intéressé dans le stage ou à mettre en cause la continuité de la formation qu’il reçoit. Selon la gravité du manquement constaté, la sanction pourra consister :</p>
        <ul>
          <li>Soit en un avertissement ; Soit en un blâme ou un rappel à l’ordre ;</li>
          <li>
            Soit en une mesure d’exclusion définitive (il est rappelé que dans la convention passée par l’organisme avec l’État ou la Région, des dispositions particulières sont définies en cas d’application des sanctions énoncées ci-dessus). Les amendes ou autres sanctions pécuniaires sont interdites. Le responsable de l’organisme de formation de l’organisme doit informer de la sanction prise l’employeur, lorsque le stagiaire est un salarié bénéficiant d’un stage dans le cadre du plan de formation en entreprise ;
          </li>
          <li>
            L’employeur et l’organisme paritaire qui a pris à sa charge les dépenses de la formation, lorsque le stagiaire est un salarié bénéficiant d’un stage dans le cadre d’un congé de formation.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-title
        class="d-flex justify-left conventionBlueTitle"
        primary-title
      >
        <h4>
          Article 9 : Procédure disciplinaire
        </h4>
      </v-card-title>
      <v-card-text>
        <p>Les dispositions qui suivent constituent la reprise des articles R 6352-4 à R 6352-8 du Code du Travail. Aucune sanction ne peut être infligée au stagiaire sans que celui-ci ait été informé au préalable des griefs retenus contre lui.</p>
        <p>
          Lorsque le responsable de l’organisme de formation ou son représentant envisagent de prendre une sanction qui a une incidence, immédiate ou non, sur la présence d’un stagiaire dans une formation, il est procédé ainsi qu’il suit :
        </p>
        <ul>
          <li>Le responsable de l’organisme de formation ou son représentant convoque le stagiaire en lui indiquant l’objet de cette convocation.</li>
          <li>
            Celle-ci précise la date, l’heure et le lieu de l’entretien. Elle est écrite et est adressée par lettre recommandée ou remise à l’intéressé contre décharge.
          </li>
          <li>
            Au cours de l’entretien, le stagiaire peut se faire assister par une personne de son choix, stagiaire ou salarié de l’organisme de formation.
          </li>
          <li>
            La convocation mentionnée à l’alinéa précédent fait état de cette faculté. Le responsable de l’organisme de formation ou son représentant indique le motif de la sanction envisagée et recueille les explications du stagiaire. Dans le cas où une exclusion définitive du stage est envisagée et où il existe un conseil de perfectionnement, celui-ci est constitué de La direction, où siègent les représentants des stagiaires.
          </li>
          <li>
            Il est saisi par le responsable de l’organisme de formation ou son représentant après l’entretien susvisé et formule un avis sur la mesure d’exclusion envisagée.
          </li>
          <li>Le stagiaire est avisé de cette saisine. Il est entendu sur sa demande par La direction. Il peut, dans ce cas, être assisté par une personne de son choix, stagiaire ou salarié de l’organisme. La direction transmet son avis au directeur de l’organisme dans le délai d’un jour franc après sa réunion.</li>
          <li>
            La sanction ne peut intervenir moins d’un jour franc ni plus de quinze jours après l’entretien ou, le cas échéant, après la transmission de l’avis de La direction. Elle fait l’objet d’une décision écrite et motivée, notifiée au stagiaire sous la forme d’une lettre qui lui est remise contre décharge ou d’une lettre recommandée.
          </li>
          <li>
            Lorsque l’agissement a donné lieu à une sanction immédiate (exclusion, mise à pied), aucune sanction définitive, relative à cet agissement ne peut être prise sans que le stagiaire ait été informé au préalable des griefs retenus contre lui et éventuellement que la procédure ci-dessus décrite ait été respectée.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      outlined
      class="conv-bloc mt-3"
    >
      <v-card-text class="conv-bloc">
        Fait en double exemplaire, à {{ data.place }}  le {{ data.date }},
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-space-around text-center">
        <div>
          <p>
            Pour l'entreprise
          </p>
          <p><i>(Nom et qualité du signataire)</i></p>
          <p class="mt-2">
            <b>{{ data.bc.managerName }}</b>
            <br>
            <b>{{ data.bc.managerFunction }}</b>
          </p>
        </div>
        <div>
          <p>
            Pour l'organisme
          </p>
          <p><i>(Nom et qualité du signataire)</i></p>
          <img
            v-if="data.companySelected === 'cm'"
            width="250px"
            src="@/assets/images/logos/tampon-signature-formapro.png"
            alt=""
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Generation convention',
    titleTemplate: '%s - Jimbo',
  },
  data() {
    return {
      icons: {
        mdiBriefcase,
      },
      data: {
        place: '',
        date: '',
        bc: {
          name: '',
          addressLine1: '',
          city: '',
          siret: '',
          phone: '',
          email: '',
          person: '',
        },
        course: {
          name: '',
          teacher: '',
          dates: '',
          duration: '',
          location: '',
          price: 0,
          hours: 0,
          students: 1,
          catering: 0,
          cateringHours: 0,
          cateringStudents: 0,
          housing: 0,
          housingHours: 0,
          housingStudents: 0,
          total: 0,
          acompte: 0,
          topay: 0,
        },
        students: [{
          firstname: '',
          lastname: '',
          role: '',
        }],
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/template/pdf-infos/${this.$route.params.id}`)
        .then(res => {
          this.data = res.data.customInfos
          console.log(res)
        })
        .catch(err => console.log(err))
        .finally()
    },
  },
}
</script>

<style>
.conventionWrapper {
  background: #ffffff;
}
.conventionBlueTitle{
  background: rgb(17, 144, 203);
}
.conventionWrapper .v-card__title h4 {
  color: #ffffff !important;
}
.theme--light.v-sheet h3,
.theme--light.v-sheet h5,
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle,
.theme--light.v-data-table th,
.theme--light.v-data-table td {
  color: #000000 !important;
}
@page {
  size: A4 portrait;
  margin: 25px;
  }
p{
  margin: 15px;
  color: black;
}
h2{
  margin: 20px;
}
@media print {
  .conv-bloc{
       break-inside: avoid;
  }
}
</style>
